@import '~style';

$block-name: 'b-form';

.b-services-detail {
	.#{$block-name} {
		@include breakpoint(medium down) {
			margin-left: -40px;
			margin-right: -40px;
		}

		&__form-container {
			margin: 0;
			max-width: 100%;
			padding: 0;
		}

		&__container {
			max-width: 100%;
			margin: 0;
		}

		&__submit {
			padding: 18px 30px;
		}
	}
}

.#{$block-name} {
	margin-top: 30px;
	margin-bottom: 70px;

	@include breakpoint(medium) {
		margin-top: 50px;
	}

	@include breakpoint(large) {
		margin-top: 80px;
	}

	&__form-container {
		@include breakpoint(smedium) {
			@include blockContainer;
		}
	}

	&__container {
		margin: 0 auto;
		max-width: 900px;
		width: 100%;
	}

	&__top {
		background-color: $color-link-blue;
		display: flex;
		align-items: center;
		padding: 25px 35px;
		margin-bottom: 5px;
	}

	&__icon {
		display: block;
		background: url('img/accept.svg') no-repeat;
		width: 25px;
		height: 26px;
		margin-right: 20px;
	}

	&__title {
		color: $color-white;
		font-size: 16px;
		margin-top: 3px;
		font-weight: 600;

		@include breakpoint(medium) {
			font-size: 20px;
			margin-top: 0;
		}
	}

	&__content {
		padding: 40px;
		background-color: $color-items;
	}

	&__inputs {
		display: flex;
		flex-wrap: wrap;
	}

	&__input {
		width: 100%;
		padding: 18px 24px;
		color: $color-black;
		background-color: $color-items;
		border: 1px solid rgba($color-link-blue, 0.5);
		outline: none;

		&:first-child {
			margin-bottom: 10px;
		}

		&::placeholder {
			color: $color-form-placeholder;
		}

		&:focus-visible {
			outline: none;
		}

		&._invalid {
			border-bottom: 1px solid $color-red;

			&::placeholder {
				color: $color-red;
			}
		}

		@include breakpoint(smedium) {
			width: calc(50% - 10px);

			&:first-child {
				margin-bottom: 0;
				margin-right: 20px;
			}
		}
	}

	&__textarea {
		width: 100%;
		padding: 18px 24px 0;
		background-color: $color-items;
		margin-top: 10px;
		outline: none;

		&::placeholder {
			color: $color-form-placeholder;
		}

		&:focus-visible {
			outline: none;
		}

		&._invalid {
			border-bottom: 1px solid $color-red;

			&::placeholder {
				color: $color-red;
			}
		}

		@include breakpoint(smedium) {
			margin-top: 20px;
		}
	}

	&__button {
		margin-top: 25px;

		@include breakpoint(large) {
			display: flex;
		}
	}

	&__submit {
		cursor: pointer;
		padding: 18px 70px;
		background-color: transparent;
		border: 1px solid $color-orange;
		color: $color-orange;
		margin-right: 30px;
		margin-bottom: 30px;
		width: 100%;
		transition: color 0.3s, background 0.3s;

		&:hover {
			background-color: $color-orange;
			color: $color-white;
		}

		@include breakpoint(large) {
			width: initial;
			margin-bottom: 0;
		}
	}

	&__personal {
		display: block;
		order: 1;
		color: $color-form-placeholder;
		line-height: 24px;
		font-size: 14px;
		margin-bottom: 30px;

		@include breakpoint(medium) {
			font-size: 16px;
		}

		@include breakpoint(large) {
			margin-bottom: 0;
		}

		input {
			display: none;
		}

		label {
			display: flex;
		}

		a {
			color: $color-link-blue;
		}
	}

	&__checkbox {
		display: flex;

		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	&__fake-checkbox {
		position: relative;
		display: flex;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: $color-white;
		border: 1px solid rgba($color-link-blue, 50%);
		margin-right: 8px;
		cursor: pointer;

		&._checked {
			&::after {
				content: '';
				width: 15px;
				height: 15px;
				top: 0;
				left: 0;
				background: url('img/checkbox.svg') no-repeat;
			}
		}

		&._invalid {
			border-color: $color-red;
		}
	}

	&__link {
		color: $color-link-blue;
		text-decoration: none;
	}
}
