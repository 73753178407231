@import '~style';

$block-name: 'b-menu';

.b-header__menu {
	.#{$block-name} {
		display: flex;

		&._bg {
			.#{$block-name} {
				&__link {
					color: $color-black !important;
				}
			}
		}

		&._white {
			.#{$block-name} {
				&__link {
					color: $color-white;

					&:hover {
						color: $color-main;
					}
				}
			}
		}

		&__item {
			margin-right: 25px;
			position: relative;
			display: flex;
			align-items: center;

			&:not(:last-child) {
				&::after {
					content: '';
					display: block;
					margin-left: 25px;
					width: 2px;
					height: 18px;
					background-color: $color-main;
				}
			}
		}

		&__link {
			color: $color-white;
			text-decoration: none;
			text-transform: uppercase;
			font-size: 13px;
			transition: color 0.3s;

			&:hover {
				color: $color-main;
			}

			&._active {
				color: $color-white !important;
			}
		}
	}
}
