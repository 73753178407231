// Пользовательские переменные

// Цвета, по возможности желательно придерживаться этого формата
$color-white: #fefefe;
$color-black: #101010;
$color-gray: #cacaca;
$color-gray-light: #e6e6e6;
$color-gray-dark: #8a8a8a;
$color-main: #c96994;
$color-orange: #c96994;
$color-light-gray: #e8ecf3;
$color-footer-gray: #999;
$color-link-blue: #184188;
$color-light-blue: #d3dbe8;
$color-gray-opacity: #b4b4b4;
$color-items: #f8f8f8;
$color-item: #ececec;
$color-form-placeholder: #999;
$color-red: #ff9f9f;
$color-form-border: #ebebeb;

// Foundation - глобальная конфигурация шрифта
$global-font-size: 16px;
$global-lineheight: 1.4;
$global-weight-normal: normal;
$global-weight-bold: bold;

// Foundation - основные параметры body
$body-background: $color-white;
$body-font-color: $color-black;
$body-font-family: 'Montserrat', helvetica, roboto, arial, sans-serif;

// Foundation - настройки сеток
$grid-columns: 12;
$global-width: 1600px;
$breakpoints: (
	small: 0,
	smedium: 520px,
	medium: 768px,
	large: 1000px,
	xlarge: 1280px,
);
$grid-margin-gutters: (
	small: 20px,
	smedium: 20px,
	medium: 20px,
	large: 20px,
	xlarge: 0,
);
$grid-container-padding: (
	small: 20px,
	smedium: 20px,
	medium: 20px,
	large: 20px,
	xlarge: 100px,
);
