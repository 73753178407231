@import '~style';

$block-name: 'b-404';

.#{$block-name} {
	margin-top: 35px;
	margin-bottom: 30px;

	&__container {
		@include blockContainer;
	}

	&__title {
		@include page-title;

		margin-bottom: 30px;
	}

	&__line {
		@include line;
	}

	&__text {
		font-size: 14px;
		margin-top: 40px;
		margin-bottom: 40px;
		text-align: center;

		p {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		@include breakpoint(medium) {
			font-size: 16px;
		}
	}
}
