// Складируйте сюда все миксины, используемые на сайте

@mixin blockContainer {
	margin: 0 auto;
	max-width: $global-width;
	padding: 0 40px;
	width: 100%;

	@include breakpoint(medium) {
		padding: 0 70px;
	}

	@include breakpoint(large) {
		padding: 0 100px;
	}
}

@mixin line {
	display: block;
	width: 95px;
	height: 2px;
	background-color: $color-main;
	margin-bottom: 30px;
}

@mixin page-title {
	font-size: 28px;
	font-weight: 400;

	@include breakpoint(medium) {
		font-size: 36px;
	}
}
