@import '~style';

$block-name: 'b-cookie';

.#{$block-name} {
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba($color-black, 0.3);
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0 40px;

	&__banner {
		max-width: 580px;
		margin-bottom: 25px;
		background-color: $color-white;
		padding: 25px 20px;
	}

	&__captchaLink {
		display: inline-block;
		margin-top: 20px;
	}

	&__text {
		font-size: 16px;
		color: $color-black;
		line-height: 28px;
		font-weight: 600;
	}

	a {
		color: $color-link-blue;
		text-decoration: underline;

		&:hover {
			text-decoration: underline;
		}
	}

	&__button {
		cursor: pointer;
		width: 100%;
		padding: 8px 20px;
		font-size: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $color-main;
		color: $color-white;
		font-weight: 500;
		margin-top: 25px;
	}
}
