@import '~style';

$block-name: 'b-success';

.#{$block-name} {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba($color-black, 0.7);
	z-index: 2000;
	display: flex;
	justify-content: center;
	align-items: center;

	&__container-close {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	&__container {
		padding: 30px;
		position: relative;
	}

	&__top {
		background-color: $color-main;
		font-size: 20px;
		font-weight: 600;
		color: $color-white;
		padding: 50px 50px 45px;
		text-align: center;

		@include breakpoint(medium) {
			padding: 50px 100px 45px;
		}
	}

	&__bottom {
		background-color: $color-white;
		color: $color-black;
		font-size: 16px;
		padding: 40px 30px;

		@include breakpoint(medium) {
			padding: 40px 100px;
		}
	}

	&__exit {
		position: absolute;
		right: 40px;
		top: 40px;
		width: 30px;
		height: 30px;
		cursor: pointer;

		span {
			display: block;
			position: absolute;
			top: 13px;
			left: 8px;
			width: 15px;
			height: 2px;
			background-color: $color-black;

			&:first-child {
				transform: rotate(45deg);
			}

			&:last-child {
				transform: rotate(-45deg);
			}
		}
	}
}
